import _ from "lodash";

export default {
  data() {
    return {
      milesCards: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          users: {
            list: [],
            selected: [],
          },
          airlines: {
            list: [],
            selected: [],
          },
          milesPrograms: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Concluído",
                value: "concluded",
              },
              {
                label: "Em edição",
                value: "edit",
              },
              {
                label: "Cancelado",
                value: "canceled",
              },
            ],
            selected: ["concluded", "edit"],
          },
          period: {
            selected: [],
          },
          supplier: "",
          id: "",
          available_miles: "",
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
      },
      processing: false,
      filters: {
        loadingResults: false,
        users: {
          list: [],
          selected: [],
        },
        airlines: {
          list: [],
          selected: [],
        },
        milesPrograms: {
          list: [],
          selected: [],
        },
        status: {
          list: [
            {
              label: "Concluído",
              value: "concluded",
            },
            {
              label: "Em edição",
              value: "edit",
            },
            {
              label: "Cancelado",
              value: "canceled",
            },
          ],
          selected: ["concluded", "edit"],
        },
        period: {
          selected: [],
        },
        supplier: "",
        id: "",
        available_miles: "",
        price: {
          min: "",
          max: "",
        },
        order: "desc",
        orderBy: "created",
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    mapMilesCardsList(arr) {
      return arr.map(({ id, miles_program }) => {
        return {
          label: `ID ${id} ${miles_program}`,
          value: id,
        };
      });
    },
    getMilesCards() {
      this.milesCards.loading = true;

      let filters = "";

      if (this.milesCards.filters.id) {
        filters += `&id=${this.milesCards.filters.id}`;
      }

      if (this.milesCards.filters.supplier) {
        filters += `&supplier=${this.milesCards.filters.supplier}`;
      }

      if (
        !this.$root.isAdmin() &&
        !this.$root.isMilesManager() &&
        this.$store.state.userData.id != 50
      ) {
        filters += `&user=${this.$store.state.userData.id}`;
      } else {
        if (this.milesCards.filters.users.selected.length > 0) {
          filters += `&user=${this.milesCards.filters.users.selected}`;
        }
      }

      if (this.activeTab == "available") {
        filters += "&status=available";
      }

      if (this.activeTab == "blocked") {
        filters += "&status=blocked";
      }

      if (this.activeTab == "canceled") {
        filters += "&status=canceled";
      }

      if (this.milesCards.filters.airlines.selected.length > 0) {
        filters += `&airline=${this.milesCards.filters.airlines.selected}`;
      }

      if (this.milesCards.filters.milesPrograms.selected.length > 0) {
        filters += `&miles-program=${this.milesCards.filters.milesPrograms.selected}`;
      }

      if (this.milesCards.filters.period.selected.length > 0) {
        filters += `&period=${this.milesCards.filters.period.selected[0]}|${this.milesCards.filters.period.selected[1]}`;
      }

      if (this.milesCards.filters.available_miles) {
        filters += `&available_miles=${this.milesCards.filters.available_miles}`;
      }

      filters += `&order=${this.milesCards.filters.order}&order-by=${this.milesCards.filters.orderBy}`;

      this.$http
        .get(
          `/miles-card/list?page=${this.milesCards.pagination.page}&per_page=${this.milesCards.pagination.perPage}${filters}`
        )
        .then(
          ({
            data: {
              data,
              meta: { total },
              totalMiles,
              totalAvailableMiles,
              totalSpentMiles,
              totalMoney,
              totalAvailableMoney,
              totalMoneySpent,
            },
          }) => {
            totalAvailableMiles;
            totalSpentMiles;
            this.pagination.total = total;

            this.milesCards.pagination.total = total;
            this.milesCards.list = [...data];
            this.listMilesCards = [...data];

            this.totalMilesCards = total;

            this.totalMiles = totalMiles;
            this.totalAvailableMiles = totalAvailableMiles;
            this.totalSpentMiles = totalSpentMiles;

            this.totalMoney = totalMoney;
            this.totalAvailableMoney = totalAvailableMoney;
            this.totalMoneySpent = totalMoneySpent;

            this.excelFile.data = [...data];

            this.excelFile.footer = [];

            this.excelFile.footer.push(" ");

            this.excelFile.footer.push(`Nº CARTÕES: ${total}`);

            this.excelFile.footer.push(`TOTAL MILHAS: ${totalMiles}`);

            this.excelFile.footer.push(`DISPONÍVEL: R$ ${totalAvailableMiles}`);

            this.excelFile.footer.push(`UTILIZADO: R$ ${totalSpentMiles}`);
          }
        )
        .catch(({ response }) => {
          console.log(response.data);
          this.pagination.total = response.data.meta.total;
          this.milesCards.list = response.data.data;

          this.totalMilesCards = response.data.totalMiles;

          this.totalMiles = response.data.totalMiles;
          this.totalAvailableMiles = response.data.totalAvailableMiles;
          this.totalSpentMiles = response.data.totalSpentMiles;

          this.totalMoney = response.data.totalMoney;
          this.totalAvailableMoney = response.data.totalAvailableMoney;
          this.totalMoneySpent = response.data.totalMoneySpent;

          this.excelFile.data = response.data.data;
        })
        .finally(() => {
          this.milesCards.loading = false;
        });
    },
    changePage(current) {
      this.milesCards.pagination.page = current;
      this.getMilesCards();
    },
    changePageSize(current, pageSize) {
      this.milesCards.pagination.page = current;
      this.milesCards.pagination.perPage = pageSize;

      this.getMilesCards();
    },
    onPageChange({ current }) {
      this.getMilesCards(current);
    },
    milesCardsTableChange(pagination, filters, sorter) {
      console.log("sorter s", sorter, pagination, filters);
      this.milesCards.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.milesCards.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getMilesCards();
    },
    getAirlines() {
      this.$http
        .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
        .then(({ data }) => {
          data;

          this.airlines = data.data;

          let airlines = data.data.map((airline) => {
            return {
              label: airline.name,
              value: airline.id,
            };
          });

          let milesPrograms = [];

          data.data.forEach((airline) => {
            airline.miles_programs.forEach((program) => {
              milesPrograms.push({
                label: program.name,
                value: program.name,
              });
            });
          });

          this.milesCards.filters.airlines.list = airlines;
          this.milesCards.filters.milesPrograms.list = milesPrograms;
        })
        .catch(({ response }) => {
          response;
        });
    },
    getUsers() {
      this.$http
        .get("/user/read")
        .then(({ data }) => {
          this.milesCards.users.list = data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    searchSupplier: _.debounce(function (e) {
      const serachText = e?.target?.value ? e.target.value : e;
      this.searchSuppliers(1, serachText);
    }, 400),
    searchSuppliers(page, searchText) {
      this.loading = true;
      const params = {
        page,
        s: searchText,
        per_page: 10,
      };
      this.$http
        .get("/supplier/search", {
          params,
        })
        .then(
          ({
            data: {
              data,
              meta: { total },
            },
          }) => {
            this.pagination.total = total;
            this.suppliers = [...data];
          }
        )
        .catch((error) => {
          console.log(error);
          this.suppliers = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSupplier(id) {
      this.processing = true;
      this.$http
        .post("/supplier/details", {
          id,
        })
        .then(({ data }) => {
          delete data.user;
          this.$emit("getSupplierRes", {
            ...data,
          });
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            this.$message.error(message);
          }
        )
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
